<style lang="scss" scoped>
.basic-info-form {
    .box-flex {
        display: flex;
    }
}
</style>


<template>
    <div class="basic-info-form">
        <section-box title="基本信息">
            <el-form :model="form" :rules="rules" ref="form" label-position="right" :label-width="labelWidth"
                     size="small">
                <el-form-item label="活动ID:" v-if="form.activitiesNo">
                    {{ form.activitiesNo }}
                </el-form-item>
                <el-form-item label="活动类型:">
                    <el-button
                        v-for="item in typeItem"
                        :key="item.value"
                        :type="form.activitiesType === item.value ? 'primary': ''"
                        :disabled="disabled"
                        @click="handleType(item)">
                        {{ item.label }}
                    </el-button>
                </el-form-item>
                <el-form-item label="活动名称:" prop="activitiesName">
                    <el-input
                        style="width: 500px"
                        v-model="form.activitiesName"
                        :disabled="disabled"
                        placeholder="新人福利">
                    </el-input>
                </el-form-item>
                <el-form-item label="活动时间:" prop="activitiesTime">
                    <el-date-picker
                        v-model="form.activitiesTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :disabled="disabled"
                        @change="changeActivitiesTime">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="活动状态:" v-if="form.activitiesStatus">
                    {{ form.activitiesStatus | formatDesc(statusItem) }}
                    <!--                    <el-button-->
                    <!--                        v-for="item in statusItem"-->
                    <!--                        :key="item.value"-->
                    <!--                        :type="form.activitiesStatus === item.value ? 'primary': ''"-->
                    <!--                        :disabled="disabled"-->
                    <!--                        @click="handleStatus(item)">-->
                    <!--                        {{ item.label }}-->
                    <!--                    </el-button>-->
                </el-form-item>
                <el-form-item label="活动渠道:" prop="activitiesOnlineChannel">
                    <el-select v-model="form.activitiesOnlineChannel" placeholder="请选择活动渠道">
                        <el-option
                            v-for="item in channelItem"
                            :key="item.value"
                            :label="item.label"
                            :disabled="disabled"
                            :value="item.value">
                        </el-option>
                    </el-select>

                    <!--                    <all-check-box-->
                    <!--                        :list="channelItem"-->
                    <!--                        v-model="form.activitiesOnlineChannel"-->
                    <!--                        @update:checked="changeOnlineChannel"-->
                    <!--                        :disabled="disabled"-->
                    <!--                        ref="all-check-box-channel-ref">-->
                    <!--                    </all-check-box>-->
                </el-form-item>
                <el-form-item label="活动范围:" prop="activitiesCityDtoList">
                    <ul>
                        <li class="box-flex">
                            <el-checkbox
                                v-model="form.cityAllChecked"
                                :disabled="disabled"
                                @change="changeRangeChecked">
                                全部
                            </el-checkbox>
                            <el-select
                                style="width: 443px"
                                class="ml10"
                                v-model="form.activitiesCityDtoList"
                                multiple
                                filterable
                                :disabled="form.cityAllChecked || disabled"
                                placeholder="请选择服务城市">
                                <el-option
                                    v-for="cityItem in cityList"
                                    :key="cityItem.id"
                                    :label="cityItem.name"
                                    :value="cityItem.shortCode">
                                </el-option>
                            </el-select>
                        </li>
                        <li>
                            限地理位置符合活动范围的用户可参与活动
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item label="活动入口:" prop="activitiesEntrance" v-if="form.activitiesType === 1">
                    <ul>
                        <li>
                            <all-check-box
                                :list="entranceItem"
                                :dataModel="form.activitiesEntrance"
                                @update:checked="handleEntrance"
                                v-model="form.activitiesEntrance"
                                type="1"
                                :disabled="disabled"
                                ref="all-check-box-entrance-ref">
                            </all-check-box>
                        </li>
                        <li>
                            限地理位置符合活动范围的用户可见活动入口
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item label="图片上传:" prop="entranceImgList" v-if="form.activitiesType === 1">
                    <ul v-if="form.entranceImgList && form.entranceImgList.length > 0">
                        <li style="display: flex;margin-bottom: 10px" v-for="item in form.entranceImgList"
                            :key="item.value">
                            <p style="width: 110px">{{ item.label }}</p>
                            <image-upload
                                style="display: inline-block"
                                :id="item.field"
                                :url="item[item.field]"
                                :sampleUrl="item[item.field + 'sample']"
                                :disabled="disabled"
                                @onFileUpload="handleImageUpload">
                                <span class="font_s12" slot="img-w-h">
                                    建议上传{{item.w}}像素 * {{item.h}}像素的图片
                                </span>
                            </image-upload>
                        </li>
                    </ul>
                    <p v-else>请先选择活动入口</p>
                </el-form-item>
            </el-form>
        </section-box>
    </div>

</template>

<script type="text/ecmascript-6">
import sectionBox from '../../../../components/section-box'
import {
    activityTypeItem,
    activityStatusItem,
    activityChannelItem,
    activityEntranceItem,
    entranceSampleImgItem
} from '../../../../data'
import allCheckBox from '../../../../components/all-check-box'
import imageUpload from '../../../../components/image-upload'
import {dateTimeStamp} from "@/common/js/dayjsDate";
import {getCityData} from "@/urls";
import {getCheckArray, checkStringFun} from '@/common/js/Utils'

const allCity = [{cityId: '999', cityName: '全部城市'}]
const initForm = {
    activitiesNo: '', // 活动编号
    activitiesType: 0, // 活动类型
    activitiesName: '', // 活动名称
    activitiesTime: [], // 活动时间  开始时间-结束时间
    activitiesStartTime: '',  // 活动开始时间
    activitiesEndTime: '', // 活动结束时间
    activitiesStatus: 0, // 活动状态
    activitiesOnlineChannel: '',  // 活动渠道
    activitiesCityDtoList: [], // 活动范围
    cityAllChecked: false, // 活动范围全部
    activitiesEntrance: [], // 活动入口
    entranceImgList: [],  // 图片上传list
    // activitiesEntranceImg: {} // 图片上传
}
export default {
    data() {
        return {
            form: {
                ...initForm
            },
            typeItem: activityTypeItem,
            statusItem: activityStatusItem,
            channelItem: activityChannelItem,
            entranceItem: activityEntranceItem,
            sampleImgItem: entranceSampleImgItem,
            cityList: [], // 城市列表
            selectEntrance: [],
            rules: {
                activitiesName: [
                    {required: true, message: '请输入活动名称', trigger: 'blur'}
                ],
                activitiesTime: [
                    {required: true, message: '请选择活动时间', trigger: 'change'}
                ],
                activitiesOnlineChannel: [
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback()
                            } else callback(new Error('请选择活动渠道'));
                        },
                        trigger: 'change'
                    }
                ],

                activitiesCityDtoList: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.cityAllChecked) callback()
                            if (value && value.length > 0) {
                                callback()
                            } else callback(new Error('请选择活动范围'));
                        },
                        trigger: 'change'
                    }
                ],
                activitiesEntrance: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback()
                            } else callback(new Error('请选择活动入口'));
                        },
                        trigger: 'change'
                    }
                ],
                entranceImgList: [
                    {
                        validator: (rule, value, callback) => {
                            let imgList = JSON.parse(JSON.stringify(this.form.entranceImgList))
                            if(imgList && imgList.length > 0) {
                                for(let i = 0; i < imgList.length; i++){
                                    if(!imgList[i][imgList[i].field]) return callback(new Error(`请上传${imgList[i].label}入口的图片`))
                                }
                                callback()
                            } else callback(new Error('请上传对应入口的图片'))
                        },
                        trigger: 'change'
                    }
                ]

            }
        }
    },
    props: {
        dataForm: {
            type: Object,
            delete: () => ({})
        },
        type: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelWidth: {
            type: String,
            default: '100px'
        }
    },
    components: {
        sectionBox,
        allCheckBox,
        imageUpload
    },
    //计算属性
    computed: {},
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getCityList();
            if (this.dataForm && this.type !== '0') {
                let {
                        activitiesNo,
                        activitiesType,
                        activitiesName,
                        activitiesStartTime,
                        activitiesEndTime,
                        activitiesStatus,
                        activitiesOnlineChannel,
                        activitiesCityDtoList,
                        activitiesEntrance,
                    } = this.dataForm,
                    dataForm = {
                        activitiesNo,
                        activitiesType,
                        activitiesName,
                        activitiesStartTime,
                        activitiesEndTime,
                        activitiesStatus,
                    };
                dataForm.activitiesTime = [activitiesStartTime, activitiesEndTime]

                this.handleType({value: activitiesType})
                // 活动渠道
                if (activitiesOnlineChannel) {
                    dataForm.activitiesOnlineChannel = Number(activitiesOnlineChannel)
                }
                // 活动范围
                if (activitiesCityDtoList) {
                    let dataCityList = JSON.parse(JSON.stringify(activitiesCityDtoList))
                    if (dataCityList[0].cityName === '全部城市') {
                        dataForm.cityAllChecked = true
                    } else {
                        dataForm.cityAllChecked = false
                        dataForm.activitiesCityDtoList = dataCityList.map(item => {
                            return item.cityId
                        })
                    }
                }
                // 活动入口
                if (activitiesEntrance && activitiesType === 1) {
                    let entrance = getCheckArray(activitiesEntrance, this.entranceItem)
                    dataForm.activitiesEntrance = entrance
                    // this.$refs['all-check-box-entrance-ref'].changeCheckedList(entrance)
                    dataForm.entranceImgList = this.handleEntranceData(entrance)
                }
                this.form = dataForm
            }
        },
        // 获取城市
        async getCityList() {
            const res = await getCityData(this, '2');
            if (res) this.cityList = res;
        },
        // 活动类型
        handleType(item) {
            this.form.activitiesType = item.value
            this.$refs['form'].clearValidate('activitiesEntrance')
            this.$emit('update:activitiesType', item.value)
        },
        // 活动时间
        changeActivitiesTime(arr) {
            this.form.activitiesStartTime = dateTimeStamp(arr[0], '00:00:00'); // 开始时间
            this.form.activitiesEndTime = dateTimeStamp(arr[1], '23:59:59'); // 结束时间
        },

        // 活动渠道
        // changeOnlineChannel() {
        //     this.$refs['form'].validateField('activitiesOnlineChannel')
        //     // let channelList = this.form.activitiesOnlineChannel
        //     // let list = []
        //     // let entranceList = []  // 入口
        //
        //     // 活动范围
        //     // let rangeList = this.channelItem.map(item => {
        //     //     let obj = {}
        //     //     channelList.map(item1 => {
        //     //         if(item.value === item1) {
        //     //             item.rangeCityList = []
        //     //             item.cityAllChecked = false
        //     //             obj = item
        //     //         }
        //     //     })
        //     //     return obj
        //     // })
        //     // 活动入口
        //     // let entranceList = this.channelItem.map(item => {
        //     //     let obj = {}
        //     //     channelList.map(item1 => {
        //     //         if(item.value === item1) {
        //     //             item.entranceItem = []
        //     //             obj = item
        //     //         }
        //     //     })
        //     //     return obj
        //     // })
        //
        //     // if(channelList && channelList.length > 0){
        //     //     this.form.activitiesEntrance = entranceList
        //     //     // this.form.activitiesCityDtoList = rangeList
        //     // } else {
        //     //     this.form.activitiesEntrance = []
        //     //     // this.form.activitiesCityDtoList = []
        //     // }
        //
        // },
        // 活动范围 checked
        changeRangeChecked(bool) {
            if (bool) this.form.activitiesCityDtoList = []
        },
        // 范围 城市
        // handleCityRang(){
        // let rangeList = JSON.parse(JSON.stringify(this.form.activitiesCityDtoList))
        // rangeList[index].rangeCityList = arr
        // this.form.activitiesCityDtoList = rangeList
        // },
        // 活动入口
        handleEntranceData(arr) {
            let entranceImgList = JSON.parse(JSON.stringify(this.form.entranceImgList))
            this.$refs['form'].validateField('activitiesEntrance')
            // let list = JSON.parse(JSON.stringify(arr))
            let entranceList = JSON.parse(JSON.stringify(this.entranceItem))
            let sampleImgList = JSON.parse(JSON.stringify(this.sampleImgItem))
            let newList = arr.map(item => {
                let obj = {}
                entranceList.map(item1 => {
                    if (item === item1.value) {
                        obj = item1
                        obj[item1.field + 'sample'] = sampleImgList[item1.field]
                        if (this.dataForm && this.type !== '0') {
                            let {
                                activitiesEntranceImg
                            } = this.dataForm
                            let entranceImg = JSON.parse(activitiesEntranceImg)
                            obj[item1.field] = entranceImg[item1.field]
                        }
                    }
                })
                if(entranceImgList && entranceImgList.length > 0){
                    entranceImgList.map(imgItem => {
                        if (item === imgItem.value) {
                            obj = {
                                ...obj,
                                ...imgItem
                            }
                        }
                    })
                }
                return obj
            })
            return newList
        },
        handleEntrance(arr) {
            this.form.entranceImgList = this.handleEntranceData(arr)
            this.$refs['form'].validateField('entranceImgList')
        },

        handleImageUpload({id, url}) {
            let form = JSON.parse(JSON.stringify(this.form))
            form.entranceImgList.map(item => {
                let obj = item
                if (item.field === id) obj[id] = url
                return item
            })
            this.form = form
            this.$refs['form'].validateField('entranceImgList')
        },

        submit() {
            return new Promise((resolve, reject) => {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let {
                                activitiesType, // 活动类型
                                activitiesName, // 活动名称
                                activitiesStartTime,  // 活动开始时间
                                activitiesEndTime, // 活动结束时间
                                activitiesOnlineChannel,  // 活动渠道
                                activitiesCityDtoList, // 活动范围
                                cityAllChecked, // 活动范围全部
                                activitiesEntrance, // 活动入口
                                entranceImgList, // 图片上传
                                // activitiesEntranceImg
                            } = this.form,
                            form = {
                                activitiesType,
                                activitiesName,
                                activitiesStartTime,
                                activitiesEndTime,
                                activitiesOnlineChannel,
                            };
                        switch (activitiesType) {
                            case 1:
                                form.activitiesUrl = '/pages/activity/inviteRewards/index';
                                break;
                        }
                        if (entranceImgList) {
                            let entranceImage = {}
                            entranceImgList.forEach(item => {
                                entranceImage[item.field] = item[item.field]
                            })
                            form.activitiesEntranceImg = JSON.stringify(entranceImage)
                        }
                        if (cityAllChecked) form.activitiesCityDtoList = allCity
                        else {
                            let applyCityArr = activitiesCityDtoList.map(item => {
                                let obj = {}
                                this.cityList.map(item1 => {
                                    if (item === item1.shortCode) {
                                        obj.cityId = item1.shortCode
                                        obj.cityName = item1.name
                                    }
                                })
                                return obj
                            })
                            form.activitiesCityDtoList = applyCityArr
                        }
                        if (activitiesEntrance) {
                            form.activitiesEntrance = checkStringFun(4, activitiesEntrance)
                        }

                        resolve(form)
                    } else {
                        reject();
                        return false;
                    }
                })
            })
        },
        reset() {
            this.form = {
                ...initForm
            }
            this.$refs['all-check-box-entrance-ref'].reset()
            this.$refs['form'].resetFields()
        }
    },
    watch: {},
}
</script>
