<style lang="scss" scoped>
.share-setting-form {

}
</style>


<template>
    <div class="share-setting-form">
        <section-box title="分享设置" v-if="activitiesType === 1">
            <el-form :model="form" :rules="rules" ref="form" label-position="right" :label-width="labelWidth" size="small">
                <!--                <el-form-item label="分享内容:">-->
                <!--                    分享内容-->
                <!--                </el-form-item>-->
                <el-form-item label="分享标题:" prop="shareContent">
                    <el-input v-model="form.shareContent" placeholder="分享标题" :maxlength="20"
                              :disabled="disabled"
                              style="width: 360px"></el-input>
                    最多输入20个中文字
                </el-form-item>
                <el-form-item label="分享方式:" prop="shareChecked">
                    <el-checkbox-group v-model="form.shareChecked"
                                       :disabled="disabled" @change="changeShareChecked">
                        <el-checkbox v-for="item in shareItem" :key="item.value" :label="item.value">{{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="微信分享图片:" prop="wxShareImg" v-if="wxShareChecked">
                    <image-upload
                        id="wxShareImg"
                        :url="form.wxShareImg"
                        :disabled="disabled"
                        @onFileUpload="handleImageUpload">
                        <span slot="img-w-h">
                            建议上传{{wxShareImg.w}}像素 * {{wxShareImg.h}}像素的图片
                        </span>
                    </image-upload>
                </el-form-item>
                <el-form-item label="海报分享图片:" prop="posterShareImg" v-if="posterShareChecked">
                    <image-upload
                        id="posterShareImg"
                        :url="form.posterShareImg"
                        :disabled="disabled"
                        @onFileUpload="handleImageUpload">
                        <span slot="img-w-h">
                            建议上传{{posterShareImg.w}}像素 * {{posterShareImg.h}}像素的图片
                        </span>
                    </image-upload>
                </el-form-item>
                <!--                <el-form-item label="分享次数:">-->
                <!--                    <el-input-number v-model="form.num" :min="1" :max="10" label="描述文字"></el-input-number>份-->
                <!--                </el-form-item>-->
            </el-form>
        </section-box>
    </div>

</template>

<script type="text/ecmascript-6">
import sectionBox from '../../../../components/section-box'
import imageUpload from '../../../../components/image-upload'
import {activityShareItem} from '../../../../data/index'

const initForm = {
    shareContent: '', // 分享标题
    shareChecked: [], // 分享方式
    wxShareImg: '',  // 微信分享图片
    posterShareImg: '',  // 海报分享图片
}
export default {
    name: '',
    data() {
        return {
            form: {
                ...initForm
            },
            activitiesType: 0,
            wxShareChecked: false,  // 分享方式-微信好友
            posterShareChecked: false,  // 分享方式-生成海报
            rules: {
                shareContent: [
                    {required: true, message: '请填写分享标题', trigger: 'blur'}
                ],
                shareChecked: [
                    {type: 'array', required: true, message: '请至少选择一个分享方式', trigger: 'change'}
                ],
                wxShareImg: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.wxShareImg) {
                                callback()
                            } else callback(new Error('请选择微信分享图片'))
                        },
                        trigger: 'change'
                    }
                ],
                posterShareImg: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.posterShareImg) {
                                callback()
                            } else callback(new Error('请选择海报分享图片'))
                        },
                        trigger: 'change'
                    }
                ],
            },
            shareItem: activityShareItem
        }
    },
    props: {
        dataForm: {
            type: Object,
            delete: () => ({})
        },
        type: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelWidth: {
            type: String,
            default: '100px'
        }
    },
    components: {
        sectionBox,
        imageUpload
    },
    //计算属性
    computed: {
        wxShareImg(){
            return this.shareImgInfo(0)
        },
        posterShareImg(){
            return this.shareImgInfo(1)
        }
    },
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.dataForm && this.type !== '0') {
                let {
                        activitiesType,
                        shareContent,
                        wxShareImg,
                        posterShareImg,
                    } = this.dataForm,
                    dataForm = {
                        shareContent
                    };
                this.activitiesType = activitiesType
                let shareCheckedData = []
                if (activitiesType === 1 && (wxShareImg || posterShareImg)) {
                    if (wxShareImg) {
                        dataForm.wxShareImg = wxShareImg
                        shareCheckedData.push(0)
                    }
                    if (posterShareImg) {
                        dataForm.posterShareImg = posterShareImg
                        shareCheckedData.push(1)
                    }
                }
                dataForm.shareChecked = shareCheckedData
                this.wxShareChecked = this.handleBool(shareCheckedData, 0)
                this.posterShareChecked = this.handleBool(shareCheckedData, 1)
                this.form = dataForm
            }
        },
        handleBool(arr, val) {
            let v = arr.find(item => item === val)
            return v === val ? true : false
        },
        shareImgInfo(type){
            return this.shareItem.find(item => item.value === type)
        },
        handleActivitiesType(type){
            this.activitiesType = type
        },
        // 分享方式
        changeShareChecked(val) {
            let list = JSON.parse(JSON.stringify(val))
            this.wxShareChecked = this.handleBool(list, 0)
            this.posterShareChecked = this.handleBool(list, 1)
        },
        // 图片上传
        handleImageUpload({id, url}) {
            let form = JSON.parse(JSON.stringify(this.form))
            form[id] = url
            this.form = form
            this.$refs['form'].validateField(id)
        },

        submit() {
            return new Promise((resolve, reject) => {
                if(this.activitiesType === 0) resolve()
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let {
                                shareContent,
                                wxShareImg,
                                posterShareImg
                            } = this.form,
                            form = {
                                shareContent
                            };
                        if (this.wxShareChecked) form.wxShareImg = wxShareImg
                        if (this.posterShareChecked) form.posterShareImg = posterShareImg
                        resolve(form)
                    } else {
                        reject();
                        return false;
                    }
                })
            })
        },
        reset() {
            this.form = {
                ...initForm
            }
            this.wxShareChecked = false // 分享方式-微信好友
            this.posterShareChecked = false  // 分享方式-生成海报
            this.$refs['form'].resetFields()
        }
    },
    watch: {},
}
</script>
