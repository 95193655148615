<style lang="scss" scoped>
.product-coupon-item{
    line-height: 32px;
    .color-b{
        color: #319BF7;
    }
}
</style>


<template>
    <div class="product-coupon-item">
        <span class="color-b">{{'[' + item.couponNo + ']'}}</span> {{item.couponName}}，
        <span v-if="item.couponDerateType === 0">{{item.couponPrice}}元，</span>
        <span v-if="item.couponDerateType === 1">{{item.couponRandomMinPrice + '-' + item.couponRandomMaxPrice}}元，</span>
        有效期
        <span v-if="item.couponValidType === 0">同活动时间一致</span>
        <span v-if="item.couponValidType === 1">{{item.couponValidDay + '天'}}</span>
    </div>

</template>

<script type="text/ecmascript-6">

export default {
    name: '',
    data() {
        return {}
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
    },
    methods: {},
    watch: {},
}
</script>
