<style lang="scss" scoped>
.vouchers-setting-form {
    &::v-deep {

        .el-form-item--small {
            &.item-label-line {
                .el-form-item__label {
                    line-height: 16px;
                }
            }

        }
    }
    .coupon-del-btn{
        width: 32px;
        color: #ff3b30;
        margin-left: 5px;
    }
}
</style>


<template>
    <div class="vouchers-setting-form">
        <section-box title="发券设置">
            <el-form :model="form" :rules="rules" ref="form" label-position="right" :label-width="labelWidth"
                     size="small">
                <el-form-item label="发放内容:" prop="productCouponDtos">
                    每份包含优惠券
                    <el-popover
                        placement="bottom-start"
                        @show="handleCouponsList"
                        :disabled="disabled"
                        trigger="click">
                        <el-checkbox-group v-if="noCouponList && noCouponList.length > 0"
                                           v-model="form.productCouponDtos" @change="changeChecked">
                            <div v-for="item in noCouponList"
                                 :key="item.id">
                                <el-checkbox
                                    :label="item.id">
                                    <product-coupon-item :item="item"></product-coupon-item>
                                </el-checkbox>
                            </div>
                        </el-checkbox-group>
                        <p v-else>暂无未绑定优惠券，请先添加优惠券</p>
                        <el-button slot="reference"
                                   :disabled="disabled">选择
                        </el-button>
                    </el-popover>
                    <ul>
                        <li v-for="(item, index) in electCouponDtos" :key="item.id">
                            <product-coupon-item :item="item" style="display: inline-block"></product-coupon-item>
                            <el-button
                                class="coupon-del-btn"
                                type="text"
                                @click="handleDel(item, index)">
                                删除
                            </el-button>
                            <!--                            <el-input-number v-model="item.num" @change="changeNum(item.num, index)" :min="1" :max="99" label="数量"></el-input-number>张-->
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item label="发放条件:">
                    <ul>
                        <li>
                            条件1：受邀者未绑定[新人福利]
                        </li>
                        <li v-if="activitiesType === 1">
                            条件2：受邀者在
                            <el-input-number v-model="form.activitiesCompletionDay" :min="1"
                                             :disabled="disabled"
                                             label="天数"></el-input-number>
                            天内完成首单
                            <!--                            ，且首单实际支付金额大于等于-->
                            <!--                            <el-input-number v-model="form.num" @change="handleChange" :min="1" :max="10" label="金额"></el-input-number>元-->
                        </li>
                        <li>
                            限受邀者符合优惠券适用城市
                        </li>
                    </ul>

                </el-form-item>
                <!--                <el-form-item label="每人可领取份数:" class="item-label-line">-->
                <!--                    <el-input-number v-model="form.num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>份-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="发放方式:">-->
                <!--                    <el-button-->
                <!--                        v-for="item in issueWayItem"-->
                <!--                        :key="item.value"-->
                <!--                        :type="form.isAutoSendCoupon === item.value ? 'primary': ''"-->
                <!--                        :disabled="disabled"-->
                <!--                        @click="handleIssueWay(item)">-->
                <!--                        {{ item.label }}-->
                <!--                    </el-button>-->
                <!--                </el-form-item>-->
            </el-form>
        </section-box>

    </div>

</template>

<script type="text/ecmascript-6">
import sectionBox from '../../../../components/section-box'
// import {activityIssueWayItem} from '../../../../data'
import {noUsedListCoupon} from '@/www/urls/promotionUrls'
import productCouponItem from '../product-coupon-item'
// import {uniqueArray} from '@/common/js/Utils'

const initForm = {
    productCouponDtos: [], // 优惠券
    activitiesCompletionDay: 1,  // 活动完成时间
    // isAutoSendCoupon: 1, // 是否自动发券
}

export default {
    data() {
        return {
            form: {
                ...initForm
            },

            activitiesType: 0,
            electCouponDtos: [], // 选中绑定优惠券显示
            // issueWayItem: activityIssueWayItem,
            noCouponList: [],
            rules: {
                productCouponDtos: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback()
                            } else callback(new Error('请选择优惠券'))
                        },
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    props: {
        dataForm: {
            type: Object,
            delete: () => ({})
        },
        type: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelWidth: {
            type: String,
            default: '100px'
        },
    },
    components: {
        sectionBox,
        productCouponItem
    },
    //计算属性
    computed: {},
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.dataForm && this.type !== '0') {
                let {
                        activitiesType,
                        activitiesCompletionDay,
                        productCouponDtos,
                        // isAutoSendCoupon
                    } = this.dataForm,
                    dataForm = {
                        activitiesCompletionDay,
                    };
                this.activitiesType = activitiesType;
                if (productCouponDtos) {
                    this.electCouponDtos = productCouponDtos
                    let couponDto = JSON.parse(JSON.stringify(productCouponDtos))
                    let couponDtoArr = couponDto.map(item => {
                        let obj = ''
                        obj = item.id
                        return obj
                    })
                    dataForm.productCouponDtos = couponDtoArr
                }
                // dataForm.isAutoSendCoupon = Number(isAutoSendCoupon)
                this.form = dataForm

            }
        },
        // 选择优惠券lieb
        handleCouponsList() {
            this.getNoCouponList()

        },
        changeChecked(arr) {
            let list = []
            this.noCouponList.forEach(item => {
                item.num = 1
                arr.forEach(item1 => {
                    if (item.id == item1) {
                        list.push(item)
                    }
                })
            })
            if (this.dataForm && this.type !== '0') {
                list = [
                    ...list,
                    ...this.dataForm.productCouponDtos
                ]
            }
            this.electCouponDtos = list
        },
        handleDel(item, index){
            this.electCouponDtos.splice(index, 1)
            let list = JSON.parse(JSON.stringify(this.form.productCouponDtos))
            list.forEach((dtoId, dtoIdx) => {
                console.log(dtoId,  item.id);
                if(dtoId === item.id) list.splice(dtoIdx, 1)
            })
            this.form.productCouponDtos = list
        },
        // 未绑定优惠券列表
        async getNoCouponList() {
            let form = {}
            const res = await noUsedListCoupon(form)
            if (res.success && res.data) {
                this.noCouponList = res.data.data
            }
        },
        // 发券设置  发券内容 数量
        // changeNum(val, index){
        //     let list = JSON.parse(JSON.stringify(this.electCouponDtos))
        //     list[index].num = val
        //     this.electCouponDtos = list
        // },
        // 发放方式
        // handleIssueWay(item) {
        //     this.form.isAutoSendCoupon = item.value
        // },

        handleChange(v) {
            console.log(v);
        },
        handleActivitiesType(type){
            this.activitiesType = type;
            this.form.activitiesCompletionDay = 1
        },

        submit() {
            return new Promise((resolve, reject) => {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let {
                                productCouponDtos,
                                activitiesCompletionDay,
                                // isAutoSendCoupon,
                            } = this.form,
                            form = {
                                // isAutoSendCoupon,
                            };
                        if (this.activitiesType === 1) form.activitiesCompletionDay = activitiesCompletionDay
                        let productDto = productCouponDtos.map(item => {
                            let obj = {}
                            obj.id = item
                            return obj
                        })
                        form.productCouponDtos = productDto
                        resolve(form)
                    } else {
                        reject();
                        return false;
                    }
                })
            })
        },
        reset() {
            this.form = {
                ...initForm
            }
            this.electCouponDtos = []
            this.$refs['form'].resetFields()
        }
    },
    watch: {},
}
</script>
