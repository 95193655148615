<style lang="scss" scoped>
.coupons-form {
    background: #fff;
    padding: 0 15px;

    &::v-deep {

        .el-form-item--small {
            &.el-form-item {
                margin-bottom: 15px;

                &.margin-bottom-no {
                    margin-bottom: 0;
                }
            }

            .el-form-item__label {
                //line-height: normal;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        .el-checkbox__label {
            padding-left: 5px;
        }
    }
}
</style>

<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont coupons-form" v-loading="!loading">
                <basic-info-form
                    ref="basic-info-form-ref"
                    :dataForm="dataForm"
                    :type="type"
                    :disabled="showEdit"
                    :label-width="labelWidth"
                    @update:activitiesType="handleActivitiesType"
                    v-if="loading">
                </basic-info-form>
                <vouchers-setting-form
                    ref="vouchers-setting-form-ref"
                    :dataForm="dataForm"
                    :type="type"
                    :disabled="showEdit"
                    :label-width="labelWidth"
                    v-if="loading">
                </vouchers-setting-form>
                <share-setting-form
                    ref="share-setting-form-ref"
                    :dataForm="dataForm"
                    :type="type"
                    :disabled="showEdit"
                    :label-width="labelWidth"
                    v-if="loading">
                </share-setting-form>
                <rule-description-form
                    ref="rule-description-form-ref"
                    :dataForm="dataForm"
                    :type="type"
                    :disabled="showEdit"
                    :label-width="labelWidth"
                    v-if="loading">
                </rule-description-form>


                <create-editor-box :form="createEditorInfo"></create-editor-box>


            </div>
        </el-main>

        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont align-r">
                <el-radio-group
                    v-if="type === '1' || type === '2'"
                    v-model="showEdit"
                    size="small">
                    <el-radio :label="true">查看模式</el-radio>
                    <el-radio :label="false" v-if="type === '1'">编辑模式</el-radio>
                </el-radio-group>
                <el-button
                    style="width: 80px;margin-left: 15px"
                    v-if="type === '1'"
                    type="primary"
                    @click="handleSubmit(1)"
                    size="small">
                    保存
                </el-button>

                <el-popover
                    v-if="type === '0'"
                    placement="top"
                    width="160"
                    v-model="visible">
                    <p style="padding: 10px 0">请确认添加活动信息</p>
                    <div style="text-align: right; margin: 0">
                        <el-button
                            style="margin-right: 15px"
                            type="text"
                            @click="visible = false"
                            size="mini">
                            取消
                        </el-button>
                        <el-button
                            type="primary"
                            :loading="submitLoading"
                            v-debounce="debounceSubmit"
                            size="mini">
                            确定
                        </el-button>
                    </div>
                    <el-button
                        style="width: 80px"
                        slot="reference"
                        type="primary"
                        size="small">
                        提交
                    </el-button>
                </el-popover>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">

import basicInfoForm from './components/basic-info-form'
import vouchersSettingForm from './components/vouchers-setting-form'
import shareSettingForm from './components/share-setting-form'
import ruleDescriptionForm from './components/rule-description-form'

import createEditorBox from '../../components/create-editor-box'
import {activityTypeItem} from '../../data'
import {activitiesDetail, addActivities, updateActivities} from '@/www/urls/promotionUrls'

export default {
    name: '',
    data() {
        return {
            activityTypeItem,
            basicInfoForm: {
                checkList: []
            },
            // 基本信息
            dataForm: {},
            showEdit: false, // 模式
            loading: false,
            visible: false,
            labelWidth: '120px',
            activitiesType: 0,
            submitLoading: false,
        }
    },
    props: {
        type: {
            type: String,
            default: ''
        }
    },
    components: {
        basicInfoForm,
        vouchersSettingForm,
        shareSettingForm,
        ruleDescriptionForm,
        createEditorBox,
    },
    //计算属性
    computed: {
        createEditorInfo() {
            let {
                createUserName,
                createTime,
                updateUserName,
                updateTime
            } = this.dataForm
            let form = {
                createName: createUserName,
                createTime,
                updateName: updateUserName,
                updateTime
            }
            return form
        }
    },
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            switch (this.type) {
                case '0':
                    this.loading = true
                    // this.dataForm = {}
                    break;
                case '1':
                    await this.getActivityDetail()
                    this.showEdit = false;
                    break;
                case '2':
                    await this.getActivityDetail()
                    this.showEdit = true;
                    break;
            }
        },
        handleActivitiesType(type) {
            this.$refs['vouchers-setting-form-ref'].handleActivitiesType(type)
            this.$refs['share-setting-form-ref'].handleActivitiesType(type)
            // this.dataForm.activitiesType = type

        },
        async getActivityDetail() {
            let id = this.$route.params.id
            const res = await activitiesDetail(id)
            if (res.success && res.data) {
                this.dataForm = res.data
                this.loading = true
            }

        },
        handleChange(v) {
            console.log(v);
        },
        debounceSubmit() {
            this.handleSubmit(0)
        },
        async handleSubmit(type) {
            try {
                let basicInfo = this.$refs['basic-info-form-ref'].submit()
                let vouchersSetting = this.$refs['vouchers-setting-form-ref'].submit()
                let shareSetting = this.$refs['share-setting-form-ref'].submit()
                let ruleDescription = this.$refs['rule-description-form-ref'].submit()
                let promiseAll = await Promise.all([basicInfo, vouchersSetting, shareSetting, ruleDescription])
                if (promiseAll) {
                    this.submitLoading = true
                    let formObj = {}
                    promiseAll.forEach(item => {
                        formObj = {...formObj, ...item}
                    })
                    let res = {}
                    if (type === 0) res = await addActivities(formObj)
                    if (type === 1) {
                        let {
                            id
                        } = this.dataForm
                        formObj.id = id
                        res = await updateActivities(formObj)
                    }
                    if (res.success && res.data) {
                        // this.handleReset()
                        this.$message({
                            message: `${type === 0 ? '提交' : '保存'}成功`,
                            type: 'success'
                        });
                        if (type === 0) this.$store.commit('delete_tabs', '/addActivity');
                        if (type === 1) this.$store.commit('delete_tabs', `/editActivity/${this.$route.params.id}`);
                        await this.$router.push('/activityList')
                    }
                    this.submitLoading = false
                }

            } catch (e) {
                console.log(e);
            }
        },
        // 重置
        handleReset() {
            this.$refs['basic-info-form-ref'].reset()
            this.$refs['vouchers-setting-form-ref'].reset()
            this.$refs['share-setting-form-ref'].reset()
            this.$refs['rule-description-form-ref'].reset()
        }
    },
    watch: {},
}
</script>
