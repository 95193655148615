<style lang="scss" scoped>
    .container {
        width: 300px;
    }
</style>
<template>

    <div>
        <file-upload :id="id"
                     :title="url?'重新上传':'上传'"
                     :showFileList="false"
                     :disabled="disabled"
                     @onCompleted="handleImageUpload">
<!--            <el-button type="text" style="margin: 0 12px">预览</el-button>-->

            <look-big-images
                class="ml10"
                title="预览"
                :id="id"
                :imagesUrl="url">
            </look-big-images>
            <slot name="img-w-h"></slot>
<!--            <look-big-images-->
<!--                class="ml10"-->
<!--                title="示例"-->
<!--                :id="id"-->
<!--                :imagesUrl="sampleUrl">-->
<!--            </look-big-images>-->
        </file-upload>
    </div>

</template>

<script type="text/ecmascript-6">
    import FileUpload from '@/components/fileUpload'
    import lookBigImages from '@/components/look-big-images'

    export default {
        //定义模版数据
        data() {
            return {
                images: {
                    // carLicenseFacePhoto: require('../../../assets/images/addVehicle/carLicenseFacePhoto.png'),
                    // carLicenseBackPhoto: require('../../../assets/images/addVehicle/carLicenseBackPhoto.png'),
                    // carPhoto: require('../../../assets/images/addVehicle/carPhoto.png'),
                    // transportCertificatePhoto: require('../../../assets/images/addVehicle/transportCertificatePhoto.png'),
                }
            }
        },
        props: {
            url: {
                type: String,
                default: ''
            },
            id: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            sampleUrl: {
                type: String,
                default: ''
            }
        },
        //计算属性
        computed: {},
        components: {
            FileUpload,
            lookBigImages
        },
        //主件被加载完成
        mounted() {

        },
        //定义事件方法
        methods: {
            handleImageUpload(file) {
                this.$emit('onFileUpload', file);
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
