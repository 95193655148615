<style lang="scss" scoped>
.rule-description-form {

}
</style>


<template>
    <div class="rule-description-form">
        <section-box title="规则说明">
            <el-form :model="form" :rules="rules" ref="form" label-position="right" :label-width="labelWidth" size="small">
                <el-form-item label="活动规则:" prop="activitiesRule">
                    <tinymce
                        id="activity-rule-description"
                        :height="400"
                        @update:editor="handleEditor"
                        :disabled="disabled"
                        v-model="form.activitiesRule">
                    </tinymce>
                </el-form-item>
            </el-form>
        </section-box>
        <!--        <el-button @click="submit">提交</el-button>-->
    </div>

</template>

<script type="text/ecmascript-6">
import sectionBox from '../../../../components/section-box';
// import tinymce from '@/www/components/tinymce-vue';

const initForm = {
    activitiesRule: '' // 活动规则
}
export default {
    data() {
        return {
            form: {
                ...initForm
            },
            rules: {
                activitiesRule: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback()
                            } else callback(new Error('请填写活动规则'))
                        },
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    props: {
        dataForm: {
            type: Object,
            delete: () => ({})
        },
        type: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelWidth: {
            type: String,
            default: '100px'
        }
    },
    components: {
        sectionBox,
        tinymce: () => import('@/www/components/tinymce-vue')
    },
    //计算属性
    computed: {},
    //主件被加载完成
    activated() {
        this.init()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.dataForm && this.type !== '0') {
                let {
                        activitiesRule
                    } = this.dataForm,
                    dataForm = {
                        activitiesRule
                    };
                this.form = dataForm
            }
        },
        handleEditor() {
            this.$refs['form'].validateField('activitiesRule')
        },
        submit() {
            return new Promise((resolve, reject) => {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let {
                                activitiesRule
                            } = this.form,
                            form = {
                                activitiesRule
                            };
                        resolve(form)
                    } else {
                        reject()
                        return false
                    }
                })
            })
        },
        reset() {
            this.form = {
                ...initForm
            }
            this.$refs['form'].resetFields()
        }
    },
    watch: {},
}
</script>
